@use "shared" as *;

.footer__state {
    position: relative;
    @include caption;
    text-transform: none;
    letter-spacing: -0.11px;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 0px 56px 0px 20px;
    height: 36px;
    color: #575757;
    background: $light-gray-color;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-area: footer;
    border-left: 1px solid $gray-color;
    border-right: 1px solid $gray-color;

    @media (max-width: 1280px) {
        border-right: 0px solid $gray-color;
    }
}

.footer__ai-circle {
    position: absolute;
    right: 0px;
}

.footer__ai-spin {
    animation: spin 2s linear infinite;
}


.footer__ai-state {
    display: flex;
    align-items: center;
    gap: 9px;
    flex-shrink: 0;
}

.footer__ai-state-spin {
    animation: spin 2s linear infinite;
}

.footer__reselect-container {
    display: flex;
    flex-shrink: 0;
    gap: 20px;
    width: 250px;
    justify-content: flex-end;
}

.footer__items {
    width: 130px;
}

.footer__reselect {
    text-decoration: underline;
    cursor: pointer;
}