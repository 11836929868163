.note-identify-button {
    cursor: pointer;
    text-decoration: underline;
    position: relative;

    &:hover {
        z-index: 101;
        color: white;
        text-decoration: none;
    }
}


@keyframes identify-button-grwoing {
    0% {
        height: 0px;
    }

    100% {
        height: 558px;
    }
}