@use "shared" as *;

.upload-attach {
  margin-top: 40px;
  position: relative;
  border: 2px dashed #dad9d9;
  // height: 36vh;
  height: 342px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.upload-attach__wr {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.upload-attach__plus {
  @include body-m;
}

.upload-attach__dd {
  margin-top: 35px;
}

.upload-attach__ext {
  position: absolute;
  bottom: 32px;
  left: 32px;
  @include body-mono;
  opacity: 0.4;
}

.upload-attach__browse-bttn {
  @include body-mono;
  background-color: $light-gray-color;
  border: 0;
  padding: 11px;
  cursor: pointer;
}

.upload-attach__image {
  width: inherit;
  height: inherit;
  object-fit: contain;
  padding: 1px 0px;
  max-width: 100%;
}

.upload-attach__replace {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-attach__about {
  margin-top: 42px;
}

.upload-attach__note-textarea {
  border: 1px solid $neutral-gray-color;
  padding: 12px 10px;
  margin: 10px 0;
  height: 65px;
  overflow-y: scroll;
  width: 600px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.focus{
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.upload-attach__note {
  @include table;
  border: 0;
  width: 100%;
  resize: none;
  outline: none;
  margin: 0;
  min-height: 100%;
}

.upload-attach__descr {
  @include table;
  margin: 0;
}

.upload-attach_wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  flex-grow: 1;
  margin-bottom: 100px;
}

.upload-attach_descr {
  @include body-mono-reg;
  margin: 0;
  letter-spacing: 0;
}
