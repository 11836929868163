@use "shared" as *;

.objects {
    padding-top: 44px;
    display: grid;
    grid-template-columns: minmax(0, 400fr) minmax(0, 70fr) minmax(0, 400fr);
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 56px);
    padding-right: 50px;
}

.objects__col {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.objects__text-field-wrapper {
    display: flex;
    min-height: 40px;
    padding: 10px 10px;
    align-items: center;
    border: 1px solid $neutral-gray-color;
}

.objects__text-field {
    flex-grow: 1;
    resize: none;
    outline: none;
    border: none;
}

.objects__text-field-remove-icon {
    width: 20px;
    height: 20px;
    margin: 0px 10px;
    background-image: url("./icons/remove.svg");
    background-size: contain;
    cursor: pointer;

    &:hover {
        background-image: url("./icons/remove_hovered.svg");
    }
}

.objects__button {
    margin-top: 10px;
    gap: 20px;
    width: 100px;
    padding: 0px;
}

.objects__add-button-icon {
    width: 14px;
    height: 14px;
    background-image: url("./icons/add.svg");
    background-size: contain;
}