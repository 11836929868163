.generator {
    width: 100vw;
    height: 100vh;
    display: flex;
}

.generator__left {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    gap: 10px;
    align-items: center;
}

.generator__switcher {
    border: 1px solid black;
    height: 50px;
    display: flex;
}

.generator__mode {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    cursor: pointer;

    &_selected {
        background-color: cornflowerblue;
        cursor: default;
        pointer-events: none;
    }
}

.generator__processing {
    margin-top: 20px;
    width: 30px;
    height: 30px;
    border: 2px solid black;
    animation: spin 2s linear infinite;
}

.generator__right {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    gap: 3px;
    overflow-y: scroll;
}

.generator__model {
    cursor: pointer;
    &:hover {
        background-color: lightslategrey;
    }
    &_selected {
        background-color: cornflowerblue;
        cursor: default;
    }
}