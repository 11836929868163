@use 'shared' as *;

body {
    @include body-mono;
    margin: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
}

#root {
    position: relative;
    overflow: hidden;
}

* {
    box-sizing: border-box;
    word-wrap: break-word;
}

::-webkit-scrollbar {
    width: 9px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #C2C1C166;
    border-radius: 5px;
}

@font-face {
    font-family: 'Moderat';
    src: url('./fonts/Moderat-Light.woff2') format('woff2');
    font-weight: 300;
}

@font-face {
    font-family: 'Moderat';
    src: url('./fonts/Moderat-Regular.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: 'Moderat';
    src: url('./fonts/Moderat-Medium.woff2') format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: 'Moderat';
    src: url('./fonts/Moderat-Bold.woff2') format('woff2');
    font-weight: 700;
}

@font-face {
    font-family: 'Simplon Mono';
    src: url('./fonts/SimplonMono-Regular.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: 'Simplon Mono';
    src: url('./fonts/SimplonMono-Medium.woff2') format('woff2');
    font-weight: 500;
}

.underline {
    text-decoration: underline;
}

.brown {
    color: $brown-color;
}

.disabled {
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
}

.invisible {
    visibility: hidden;
}

.hidden {
    display: none;
}

.rotated {
    transform: rotate(180deg);
}

.highlighted {
    z-index: 101 !important;
    opacity: 1 !important;
}

.pointer {
    cursor: pointer;
}

.cursor-add {
    cursor: -webkit-image-set(url('/cursors/add@3x.png') 3x) 10 8, auto;
}

.cursor-remove {
    cursor: -webkit-image-set(url('/cursors/remove@3x.png') 3x) 9 9, auto;
}

.cursor-frame-to-add {
    cursor: -webkit-image-set(url('/cursors/frame-to-add@3x.png') 3x) 21 20, auto;
}

.cursor-selection {
    cursor: -webkit-image-set(url('/cursors/selection@3x.png') 3x) 1 1, auto;
}

.cursor-selection-add {
    cursor: -webkit-image-set(url('/cursors/selection-add@3x.png') 3x)1 1, auto;
}

.cursor-selection-remove {
    cursor: -webkit-image-set(url('/cursors/selection-remove@3x.png') 3x) 1 1, auto;
}

.cursor-window-selection {
    cursor: -webkit-image-set(url('/cursors/window-selection@3x.png') 3x) 1 1, auto;
}

.cursor-window-selection-add {
    cursor: -webkit-image-set(url('/cursors/window-selection-add@3x.png') 3x) 1 1, auto;
}

.cursor-window-selection-remove {
    cursor: -webkit-image-set(url('/cursors/window-selection-remove@3x.png') 3x) 1 1, auto;
}

.cursor-cross-selection {
    cursor: -webkit-image-set(url('/cursors/cross-selection@3x.png') 3x) 1 1, auto;
}

.cursor-cross-selection-add {
    cursor: -webkit-image-set(url('/cursors/cross-selection-add@3x.png') 3x) 1 1, auto;
}

.cursor-cross-selection-remove {
    cursor: -webkit-image-set(url('/cursors/cross-selection-remove@3x.png') 3x) 1 1, auto;
}

.cursor-match-definition {
    cursor: -webkit-image-set(url('/cursors/match-definition@3x.png') 3x) 12 25, auto;
}

.cursor-match-definition-full {
    cursor: -webkit-image-set(url('/cursors/match-definition-full@3x.png') 3x) 12 25, auto;
}

.cursor-match-definition-full .pointer {
    cursor: -webkit-image-set(url('/cursors/match-definition-full@3x.png') 3x) 12 25, auto;
}

.cursor-brush-tool {
    cursor: none;
}

.cursor-grab {
    cursor: grab;
}

.cursor-grabbing {
    cursor: grabbing;
}