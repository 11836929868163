.note {
    cursor: pointer;
    text-decoration: underline;
    position: relative;

    &:hover {
        z-index: 101;
        color: white;
        text-decoration: none;
    }
}