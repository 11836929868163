/*
@use 'shared' as *;
*/

$black-color: #000000;
$blue-color: #0463e1;
$white-color: #ffffff;
$light-blue-color: #f3fcff;
$light-gray-color: #efefef;
$gray-color: #aaa8a5;
$brown-color: #908a82;
$dark-brown-color: #5a5959;
$error-color: #f62f53;
$neutral-gray-color: #dad9d9;
$medium-gray-color: #c2c1c1;
$coconut-gray-color: #f9f9f9;
$light-green-color: #97FFC6;

@mixin h1 {
  font-family: "Moderat";
  text-transform: none;
  font-weight: 500;
  font-size: 60px;
  line-height: 100%;
  letter-spacing: -3px;
  color: $black-color;
}

@mixin h2 {
  font-family: "Moderat";
  text-transform: none;
  font-weight: 500;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: -0.72px;
  color: $black-color;
  opacity: 0.2;
}

@mixin h3 {
  font-family: "Moderat";
  text-transform: none;
  font-weight: 500;
  font-size: 22px;
  line-height: 116%;
  letter-spacing: -0.44px;
  color: $black-color;
}

@mixin h4 {
  font-family: "Moderat";
  text-transform: none;
  font-weight: 500;
  font-size: 32px;
  line-height: 122%;
  letter-spacing: -1.28px;
  color: $black-color;
}

@mixin h5 {
  font-family: "Moderat";
  text-transform: none;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 116%;
  letter-spacing: -0.44px;
  color: $black-color;
}

@mixin body-m {
  font-family: "Moderat";
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 116%;
  letter-spacing: -0.32px;
  color: $black-color;
}

@mixin body-mono {
  font-family: "Simplon Mono";
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
  line-height: 122%;
  letter-spacing: 0px;
  color: $black-color;
}

@mixin body-mono-reg {
  font-family: "Simplon Mono";
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0px;
  color: $black-color;
}

@mixin button {
  font-family: "Simplon Mono";
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  line-height: 108%;
  letter-spacing: -0.64px;
  color: $black-color;
}

@mixin table {
  font-family: "Simplon Mono";
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: -0.65px;
  color: $black-color;
}

@mixin tools {
  font-family: "Simplon Mono";
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
  line-height: 106%;
  letter-spacing: 0px;
  color: $black-color;
}

@mixin caption {
  font-family: "Simplon Mono";
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  line-height: 106%;
  color: $black-color;
}

@mixin hint {
  font-family: "Simplon Mono";
  text-transform: none;
  font-weight: 400;
  font-size: 10px;
  line-height: 122%;
  letter-spacing: 0px;
  color: $black-color;
}

@mixin tooltip {
  font-family: "Moderat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 122%;
  color: $white-color;
  text-transform: none;
}

/*
@use 'shared' as *;
*/
