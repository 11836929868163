@use "shared" as *;

.hint_wrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5;
  z-index: 8;
}

.hint {
  position: relative;
  background: $black-color;
  box-shadow: 0px 1px 3.4px 0px rgba(0, 0, 0, 0.1);
  max-width: 310px;
  display: flex;
  padding: 10px 6px;
  text-transform: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  hyphens: auto;
}

.hint>p {
  color: $white-color;
  font-family: "Simplon Mono";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.55px;
  margin: 0;
}


.hint_before_element_right {
  border: 10px solid transparent;
  border-right: 11px solid $black-color;
  width: 10px;
  height: 10px;
  margin-top: 5px;
}

.hint_before_element_bottom {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 11px solid $black-color;
  width: 10px;
  height: 10px;
  align-self: center;
  // margin-top: 5px;
}

.hint_before_element_top {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 11px solid $black-color;
  width: 10px;
  height: 10px;
  align-self: center;
}

.hint_before_element_left {
  border: 11px solid transparent;
  border-left: 11px solid $black-color;
}

.big_tooltip_wrapper {
  display: flex;
  position: absolute;
  transition: 0.5s;
  z-index: 8;
}

.big_tooltip {
  width: 404px;
  padding: 10px 12px 16px;
  display: flex;
  flex-direction: column;
  background-color: $black-color;
  box-shadow: 0px 1px 3.4px 0px rgba(0, 0, 0, 0.1);
}

.big_tooltip-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 244px;
  width: 100%;
  background-color: $gray-color;
  margin-bottom: 16px;
}

.big_tooltip-image>img {
  object-fit: contain;
  width: 100%;
}

.big_tooltip-text-wrapper {
  display: flex;
  flex-direction: column;
}

.big_tooltip-title {
  @include tools;
  text-transform: none;
  color: $white-color;
  font-size: 11px;
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
}

.big_tooltip-text {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.big_tooltip-text>p {
  @include table;
  color: $white-color;
  margin: 0;
}

.hint_spinner {
  width: 20px;
  height: 20px;
  background-image: url("./img/spinner.svg");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.extended_hint_before_bottom {
  margin-bottom: 5px;
}

.extended_hint_before_top {
  margin-top: 5px;
}

.hint_before_flex-end {
  margin-bottom: 5px;
}

.hint_before_right {
  align-self: flex-end;
  margin-right: 5px;
}