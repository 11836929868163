@use "shared" as *;

.main_notice__note-tooltip {
    position: absolute;
    width: 295px;
    height: 155px;
    background-color: $black-color;
    padding: 20px;
    right: 15px;
    bottom: 15px;
    z-index: 101;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main_notice__note-tooltip-text {
    @include body-mono;
    color: $white-color;
}

.main_notice__note-tooltip-bottom {
    display: flex;
    align-items: center;
}

.main_notice__note-tooltip-bottom-checkbox {
    margin-left: 24px;
}

.main_notice__note-tooltip-checkbox-label {
    @include table;
    color: $white-color;
    margin-left: 8px;
    transform: translate(0px, 1px);
}