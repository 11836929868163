@use "shared" as *;

.home__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.home__assistant {
  background-color: $coconut-gray-color;
  margin: 20px 30px;
  padding: 16px;
  display: flex;
  gap: 16px;
}

.home__assistant-icon {
  width: 22px;
  height: 22px;
  background-image: url(./icons/assistant.svg);
  background-size: contain;
}

.home__assistant-text {
  @include table;
  flex-grow: 1;
}

.home__assistant-text-grey {
  color: $gray-color;
}

.home__assistant-read-more {
  @include body-mono;
  text-decoration: underline;
  width: 100px;
  height: 20px;
  background-image: url(./icons/arrow.svg);
  background-position: right -1px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.home__elements_wrapper {
  flex: 1;
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(0px, 2.3fr);
  margin: 30px;
  gap: 46px;
}

.home__prototypes_list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.home__prototypes_list-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid $gray-color;
  padding-bottom: 7px;
  height: 27px;

  &>p {
    @include body-mono;
    opacity: 0.4;
    margin: 0;
  }
}

.home__create-new {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.home__create-new-icon {
  width: 20px;
  height: 20px;
  background-image: url("./icons/add.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.home__create-new-text {
  @include body-mono;
  transform: translate(0px, 1px);
}

.home__prototypes-list {
  width: 100%;
  max-height: 77vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

.home__prototypes-list-empty {
  @include body-mono;
  letter-spacing: -0.26px;
  opacity: 0.2;
}

.home_prototypes_preview-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 9px;
  border-bottom: 1px solid $gray-color;
  height: 24px;

  &>p {
    @include body-mono;
    opacity: 0.4;
    margin: 0;
  }
}

.home_prototypes_preview-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
}

.home_prototypes_preview {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.prototype_preview {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.prototype_preview-image {
  cursor: pointer;
  height: 162px;
  width: 162px;
  position: relative;
}

.prototype_preview-image-segments {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.prototype_preview-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.prototype_preview-info-date {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.preview-info-date-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 82px;

  &>p {
    margin: 0;
  }
}

.preview-info-date-title {
  opacity: 0.4;
}

.renders_wrapper {
  display: flex;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.renders_header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 9px;
  border-bottom: 1px solid #aaa8a5;
  height: 24px;

  &>p {
    @include body-mono;
    opacity: 0.4;
    margin: 0;
  }
}

.renders_header-dowload-dropdown {
  cursor: pointer;
  display: flex;
  flex-direction: row;

  &:hover {
    &>p {
      text-decoration: underline;
    }
  }
}

.renders-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.no_renders {
  @include body-mono;
  opacity: 0.2;
}

.download_dropdown-wrapper {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  &>p {
    margin: 0;
  }

  &:hover {
    &>p {
      text-decoration: underline;
    }
  }
}

.download_icon {
  width: 20px;
  height: 20px;
  background-image: url("./icons/download.svg");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.dropdow_arrow {
  width: 11px;
  height: 8px;
  background-image: url("./icons/dropdown_arrow.svg");
  background-size: 11px 8px;
  background-position: center;
  background-repeat: no-repeat;
}

.dropdow_arrow.active {
  transform: rotate(180deg);
}

.download-menu {
  top: 23px;
  right: 0px;
  z-index: 5;
  position: absolute;
  width: 125px;
  padding: 20px 0;
  border: 1px solid $gray-color;
  background: $white-color;
  display: flex;
  flex-direction: column;
}

.download-menu>p {
  margin: 0;
  cursor: pointer;
  padding: 10px 0 10px 25px;

  &:hover {
    background-color: $light-gray-color;
  }
}

.disabled_menu-item {
  opacity: 0.4;
  pointer-events: none;
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-color;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 10;
  top: 0;
  left: 0;
}