@use "shared" as *;

.error-modal__container {
  position: absolute;
  inset: 0px;
  background-color: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.share_modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.share_modal {
  position: absolute;
  z-index: 2;
  width: 336px;
  height: 204px;
  background-color: $black-color;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $black-color;
  position: relative;
}

.share_icons_wrapper {
  height: 50%;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(4, 1fr);
  padding: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.share_icons_item {
  height: 52px;
  width: 52px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share_icon {
  transition: 0.1s;
  opacity: 0.7;
  filter: invert(83%) sepia(6%) saturate(128%) hue-rotate(357deg)
    brightness(81%) contrast(91%);
}

.share_icon:hover {
  opacity: 1;
  filter: invert(99%) sepia(8%) saturate(236%) hue-rotate(351deg)
    brightness(119%) contrast(100%);
}

.copy_link_wrapper {
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  @include body-mono;
  letter-spacing: -0.26px;
  text-transform: uppercase;
  color: $gray-color;
  padding-left: 25px;
  transition: 0.1s;

  &:hover {
    color: $black-color;
    background-color: $gray-color;
    .copy_link_icon {
      filter: invert(0%) sepia(94%) saturate(7476%) hue-rotate(62deg)
        brightness(99%) contrast(96%);
    }
  }
}

.copy_link_wrapper_copyed {
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  @include body-mono;
  letter-spacing: -0.26px;
  text-transform: uppercase;
  padding-left: 25px;
  transition: 0.1s;
  color: $black-color;
  background-color: $white-color;
  .copy_link_icon {
    filter: invert(0%) sepia(94%) saturate(7476%) hue-rotate(62deg)
      brightness(99%) contrast(96%);
  }

  &:hover {
    color: $black-color;
    .copy_link_icon {
      filter: invert(0%) sepia(94%) saturate(7476%) hue-rotate(62deg)
        brightness(99%) contrast(96%);
    }
  }
}
.copy_link_icon {
  height: 52px;
  width: 52px;
  transition: 0.1s;
  padding-bottom: 5px;
  filter: invert(83%) sepia(6%) saturate(128%) hue-rotate(357deg)
    brightness(81%) contrast(91%);
}

.share_modal-close_icon {
  width: 20px;
  height: 20px;cursor: pointer;
  background-image: url('./icons/close.svg');
  background-repeat: no-repeat;
  background-position: center;
  align-self: flex-end;
  transition: 0.3s ;
  &:hover {
    filter: invert(38%) sepia(0%) saturate(70%) hue-rotate(219deg)
      brightness(87%) contrast(89%);
  }
}
