@use 'shared' as *;

.sign-in {
    background-color: $white-color;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
    width: 1056px;
    display: flex;
}

.sign-in__title-container {
    flex-basis: 50%;
    display: flex;
}

.sign-in__title {
    @include h3;
    margin-left: 52px;
    margin-top: 250px;
}

.sign-in__form-container {
    flex-basis: 450px;
    flex-shrink: 0;
    margin-top: 250px;
}

.sign-in__form-gmail {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid $black-color;
    cursor: pointer;
}

.sign-in__form-gmail-icon {
    width: 16.5px;
    height: 16.5px;
    background-image: url('./icons/google.svg');
    background-size: contain;
}

.sign-in__form-gmail-text {
    letter-spacing: -0.26px;
    transform: translate(0px, 1px);
}

.sign-in__form-title {
    margin-top: 32px;
    letter-spacing: -0.26px;
    text-align: center;
}

.sign-in__form-label {
    margin-top: 16px;
    letter-spacing: -0.26px;
}

.sign-in__form {
    margin-top: 10px;
    width: 100%;
}

.sign-in__form-button {
    @include body-mono;
    margin-top: 20px;
    width: 100%;
    letter-spacing: -0.26px;
}

.sign-in__form-checkbox-form {
    margin-top: 20px;
    display: flex;
    align-items: center;
    position: relative;
}

.sign-in__form-checkbox-label {
    @include body-mono-reg;
    letter-spacing: 0px;
    margin-left: 24px;
    transform: translate(0px, 1px);
}

.sign-in__form-sign-up {
    @include body-mono-reg;
    letter-spacing: 0px;
    margin-top: 52px;
}

.sign-in__form-sign-up-create-link {
    text-decoration: underline;
    cursor: pointer;
}

.sign-in__exit-button-container {
    flex-basis: 50%;
    display: flex;
    position: relative;
}

.sign-in__exit-button {
    width: 16px;
    height: 16px;
    background-image: url('./icons/cross.svg');
    background-size: contain;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
}

.sign-in__form-checkbox{
    left: 0;
    top: 0!important;
    opacity: 1!important;
}