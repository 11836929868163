@use "shared" as *;

.left-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  overflow-y: scroll;
  grid-area: left-bar;

  &_no-scroll {
    overflow: visible;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.left-bar__arrows {
  position: relative;
  margin-top: 16px;
  width: 100%;
  height: 56px;
  background-color: $white-color;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 21px;
}

.left-bar__arrow {
  cursor: pointer;

  &:hover {
    filter: brightness(0) saturate(100%) invert(20%) sepia(69%) saturate(3670%) hue-rotate(207deg) brightness(102%) contrast(97%);
  }
}

.left-bar__br {
  margin: 12px 0px;
  height: 1px;
  background-color: $gray-color;
  width: 89px;
}

.left-bar__cursor-buttons {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.left-bar__cursor-button {
  cursor: pointer;

  &:hover,
  &_active {
    filter: brightness(0) saturate(100%) invert(20%) sepia(69%) saturate(3670%) hue-rotate(207deg) brightness(102%) contrast(97%);
  }
}

.left-bar__edit-buttons {
  padding: 16px 0px;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.left-bar__button {
  @include tools;
  background-color: $white-color;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;

  &:hover,
  &_active {
    background-color: transparent;
    filter: brightness(0) saturate(100%) invert(20%) sepia(69%) saturate(3670%) hue-rotate(207deg) brightness(102%) contrast(97%);
  }
}

.left-bar__identify_button_animation-container {
  position: absolute;
  height: 0px;
  overflow: hidden;
  top: 0px;
  width: 100%;
  background-color: $white-color;
  opacity: 1;
}

.left-bar__identify-button {
  position: relative;
  height: 133px;
}

.left-bar__join-button {
  height: 91px;
}

.left-bar__button-special {
  &:hover,
  &_active {
    filter: none !important;
    color: $blue-color;

    .left-bar__button-point-to-exclude {
      background-image: url(./icons/point_to_exclude_active.svg);
    }
  }
}

.left-bar__button-point-to-exclude {
  background-image: url(./icons/point_to_exclude.svg);
  width: 73px;
  height: 64px;
}

.left-bar__editing-buttons {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.left-bar__done-button {
  gap: 11px;
}

.left-bar__identify-button-small-tooltip-arrow {
  position: absolute;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  left: -6px;
  background-color: $black-color;
}

.left-bar__identify-button-small-tooltip {
  position: relative;
  padding: 10px 5px;
  position: fixed;
  height: 60px;
  background-color: $black-color;
  transform: translate(0, -50%);
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.left-bar__identify-button-small-tooltip-text {
  @include caption;
  text-transform: none;
  letter-spacing: -0.55px;
  color: $white-color;
}

.left-bar__identify-button-small-tooltip-spinner {
  animation: spin 1s infinite linear;
}

.left-bar__identify-button-big-tooltip {
  position: absolute;
  width: 404px;
  padding: 10px 12px 16px;
  display: flex;
  flex-direction: column;
  z-index: 101;
  background-color: $black-color;
}

.left-bar__identify-button-big-tooltip-arrow {
  position: absolute;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  left: -6px;
  background-color: $black-color;
}

.left-bar__identify-button-big-tooltip-title {
  @include caption;
  text-transform: none;
  letter-spacing: -0.55px;
  color: $white-color;
  align-self: center;
}

.left-bar__identify-button-big-tooltip-video {
  margin-top: 10px;
  width: 380px;
  height: 244px;
  background-color: $gray-color;
}

.left-bar__identify-button-big-tooltip-about {
  @include table;
  color: $white-color;
  margin-top: 16px;
}