@use "shared" as *;

.select {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $neutral-gray-color;
  transition: 0.2s;

  &:focus {
    border-color: $black-color;
  }

  &_disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &_error {
    border: 1px solid $error-color;
  }
}

.select__placeholder {
  @include table;
  color: $gray-color;
  padding-left: 10px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &_selected {
    color: $black-color;
  }
}

.select__button {
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  background-image: url("./icons/button.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.select:focus .select__button {
  transform: rotate(180deg);
}

.select__items {
  position: absolute;
  z-index: 5;
  top: 39.5px;
  left: -0.5px;
  width: calc(100% + 1px);
  display: none;
  flex-direction: column;
  border-bottom: 1px solid $neutral-gray-color;
  border-left: 1px solid $neutral-gray-color;
  border-right: 1px solid $neutral-gray-color;
  background-color: $white-color;
  padding: 7.5px 0px;
}

.select__items-color {
  display: flex;
  position: absolute;
  z-index: 6;
  top: 39.5px;
  left: -0.5px;
  width: calc(100% + 1px);
  display: none;
  flex-direction: column;
  border-bottom: 1px solid $neutral-gray-color;
  border-left: 1px solid $neutral-gray-color;
  border-right: 1px solid $neutral-gray-color;
  background-color: $white-color;
  padding: 7.5px 0px;

  max-height: 304px;
  overflow-y: scroll;
  gap: 2px;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    background: $white-color;
  }
}

.select__item {
  @include table;
  transition: 0.2s;
  display: flex;
  align-items: center;
  padding: 7.5px 10px;
  cursor: pointer;

  &:hover {
    background-color: $light-gray-color;
  }
}

.select__color-item {
  @include table;
  transition: 0.2s;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;

  &>p {
    margin: 0;
    padding-left: 10px;
  }

  &:hover {
    background-color: $light-gray-color;
  }
}

.select:focus .select__items {
  display: flex;
}

.select:focus .select__items-color {
  display: flex;
}

.color-square {
  width: 38px;
  height: 38px;
}

.select-color-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}