@use "shared" as *;

.submit-for-rendering {
  position: absolute;
  inset: 0px;
  background: #00000080;
  z-index: 8;
}

.submit-for-rendering__window {
  background-color: $white-color;
  position: absolute;
  padding: 0px 52px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  width: 1056px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 100px 388px 1fr 1fr;
}

.submit-for-rendering__title-container {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display: flex;
  gap: 20px;
}

.submit-for-rendering__title {
  @include h5;
}

.submit-for-rendering__email {
  @include body-mono-reg;
  transform: translate(0px, 9px);
  color: $gray-color;
}

.submit-for-rendering__main {
  display: flex;
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  gap: 52px;
}

.submit-for-rendering__resolution {
  display: flex;
  flex-direction: column;
}

.submit-for-rendering__settings-resolution-radio {
  display: flex;
  align-items: center;
  text-transform: none;
  gap: 10px;

  div:last-child {
    transform: translate(0px, 1.5px);
  }

  &-px {
    margin-top: 20px;
  }

  &-mm {
    margin-top: 30px;
  }
}

.submit-for-rendering__settings-resolution-inputs {
  margin-top: 20px;
  display: flex;
  gap: 16px;
  align-items: flex-end;
  transition: 0.3s;

  &_disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.submit-for-rendering__settings-resolution-input-form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &-dpi {
    flex-grow: 1;
  }
}

.submit-for-rendering__settings-resolution-input {
  width: 119px;
}

.submit-for-rendering__settings-resolution-clip {
  cursor: pointer;
  margin-bottom: 9px;

  &-mm {
    transform: translate(-11px, 0px);
  }
}

.submit-for-rendering__br {
  height: 100%;
  width: 1px;
  background-color: $neutral-gray-color;
}

.submit-for-rendering__info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.submit-for-rendering__checkout-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.submit-for-rendering__checkout-option {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &>*:first-child {
    flex: 0.7;
  }
}

.submit-for-rendering__checkout-option-value {
  width: 130px;
}

.submit-for-rendering__checkout-amount {
  height: 39px;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &>*:last-child {
    font-family: "Moderat";
    text-transform: none;
    font-weight: 400;
    font-size: 32px;
    line-height: 122%;
    letter-spacing: -1.28px;
    color: $black-color;
  }
}

.submit-for-rendering__checkout-balance {
  margin-top: 16px;
  border-top: 1px solid $gray-color;
  padding-top: 16px;
  display: flex;
  justify-content: space-between;

  &>*:last-child {
    width: 130px;
    color: $error-color;
  }
}

.submit-for-rendering__checkout-buy-tokens {
  padding-bottom: 10px;
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  text-decoration: underline;
  cursor: pointer;

  &>*:last-child {
    width: 130px;
    transform: translate(0px, 1px);
  }
}

.submit-for-rendering__checkout-button {
  margin-top: 60px;
  width: 100%;
}

.submit-for-rendering__close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 9px;
  height: 9px;
  cursor: pointer;
}

.submit-for-rendering__successful-wr {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-column: 1 / 3;
  grid-row: 1 / 6;
}

.submit-for-rendering__successful-title {
  @include h4;
}

.submit-for-rendering__successful-text {
  margin: 32px 0;
  width: 45%;
  @include body-mono-reg;
  letter-spacing: -0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.submit-for-rendering__buttons {
  display: flex;
  gap: 10px;
}

.submit-for-rendering__continue-button {
  @include tools;
  width: 217px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black-color;
  color: $white-color;
  cursor: pointer;

  &:hover {
    background-color: $gray-color;
    color: $black-color;
  }
}

.submit-for-rendering__skip-button {
  @include tools;
  width: 78px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $black-color;
  cursor: pointer;

  &:hover {
    background-color: $gray-color;
  }
}





/* @use "shared" as *;

.submit-for-rendering {
  position: absolute;
  inset: 0px;
  background: #00000080;
  z-index: 8;
}

.submit-for-rendering__close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 9px;
  height: 9px;
  cursor: pointer;
}

.submit-for-rendering__window {
  background-color: $white-color;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  width: 1056px;
  display: flex;
  flex-direction: column;
}

.submit-for-rendering__title {
  margin-top: 52px;
  margin-left: 52px;
  display: flex;
  gap: 20px;
  align-items: center;

  & > *:first-child {
    @include h3;
  }

  & > *:nth-child(2) {
    width: 1px;
    background-color: $gray-color;
    align-self: stretch;
  }

  & > *:last-child {
    text-transform: none;
    margin-top: 4px;
  }
}

.submit-for-rendering__main {
  margin-top: 38px;
  display: flex;
  overflow-y: hidden;
}

.submit-for-rendering__settings {
  flex-basis: 560px;
  flex-direction: column;
  padding: 0px 52px 80px;
  overflow-y: scroll;
}

.submit-for-rendering__settings-fn-title {
  letter-spacing: -0.26px;
  opacity: 0.4;
}
.submit-for-rendering__settings-fn-description {
  letter-spacing: -0.26px;
  text-transform: none;
  width: 96%;
  & > p {
    margin: 0;
  }
  & > *:first-child {
    margin-bottom: 13px;
  }
}

.submit-for-rendering__settings-fn {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.submit-for-rendering__settings-fn_wrapper {
  position: relative;
}
.submit-for-rendering__settings-fn_error {
  position: absolute;
  top: calc(100% + 8px);
  @include table;
  color: $error-color;
}

.submit-for-rendering__settings-fn-select {
  margin-top: 10px;
}

.submit-for-rendering__range-input-labels {
  display: flex;
  justify-content: space-between;
  text-transform: none;
  position: relative;
}

.submit-for-rendering__range-input-label-points {
  position: absolute;
  inset: 0px;
  display: flex;
  justify-content: space-evenly;

  & > * {
    background-color: $gray-color;
    width: 1px;
    height: 6px;
    align-self: stretch;
  }
}

.submit-for-rendering__settings-condition {
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 20px;

  & > *:first-child {
    opacity: 0.4;
  }
}

.submit-for-rendering__settings-time-label {
  margin-top: 16px;
}

.submit-for-rendering__settings-time-select {
  margin-top: 10px;
}

.submit-for-rendering__settings-season-label {
  margin-top: 16px;
}

.submit-for-rendering__settings-season-select {
  margin-top: 16px;
}

.submit-for-rendering__settings-weather-label {
  margin-top: 16px;
}

.submit-for-rendering__settings-weather-select {
  margin-top: 10px;
}

.submit-for-rendering__settings-scenery-label {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.scenery-info {
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 16px;
  margin-left: 8px;
  background-image: url('./icons/info.svg');
  background-size: 18px 16px;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s;

  &:hover{
    background-image: url("./icons/info_hover.svg");
  }
}
.submit-for-rendering__settings-resolution-radio {
  margin-top: 16px;
  display: flex;
  align-items: center;
  text-transform: none;
  gap: 10px;

  div:last-child {
    transform: translate(0px, 1.5px);
  }
}

.submit-for-rendering__settings-resolution-inputs {
  margin-top: 20px;
  display: flex;
  gap: 16px;
  align-items: flex-end;
  transition: 0.3s;

  &_disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.submit-for-rendering__settings-resolution-clip {
  cursor: pointer;
  margin-bottom: 10px;
}

.submit-for-rendering__settings-resolution-input-form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &-dpi {
    flex-grow: 1;
  }
}

.submit-for-rendering__settings-resolution-input {
  width: 156px;
}

.submit-for-rendering__checkout {
  flex-basis: 496px;
  margin-bottom: 20px;
  padding: 0px 52px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $gray-color;
}

.submit-for-rendering__checkout-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 43px;

  & > *:first-child {
    opacity: 0.4;
    transform: translate(0px, 1px);
  }
}




.submit-for-rendering__checkout-options {
  margin-top: 60px;
}

.submit-for-rendering__checkout-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  & > *:first-child {
    flex: 0.7;
  }
}

.submit-for-rendering__checkout-amount {
  height: 39px;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > *:first-child {
    opacity: 0.4;
  }

  & > *:last-child {
    font-family: "Moderat";
    text-transform: none;
    font-weight: 400;
    font-size: 32px;
    line-height: 122%;
    letter-spacing: -1.28px;
    color: $black-color;
  }
}

.submit-for-rendering__checkout-balance {
  margin-top: 16px;
  border-top: 1px solid $gray-color;
  padding-top: 16px;
  display: flex;
  justify-content: space-between;

  & > *:first-child {
    opacity: 0.4;
  }

  & > *:last-child {
    color: $error-color;
  }
}

.submit-for-rendering__checkout-buy-tokens {
  padding-bottom: 10px;
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  text-decoration: underline;
  cursor: pointer;

  & > *:last-child {
    transform: translate(0px, 1px);
  }
}

.submit-for-rendering__checkout-button {
  margin-top: 40px;
  width: 100%;
}

.submit-for-rendering__settings-separator {
  width: 100%;
  height: 1px;
  background-color: $gray-color;
  margin: 40px 0;
}

.submit-for-rendering__settings-fn-rangers {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.submit-for-rendering__successful-wr {
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 52px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.submit-for-rendering__successful-title {
  @include h4;
}
.submit-for-rendering__successful-text {
  margin: 32px 0;
  width: 45%;
  @include body-mono-reg;
  letter-spacing: -0;
}
 */