@use 'shared' as *;

.sign-up-step-one {
    background-color: $white-color;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    width: 1056px;
    display: flex;
}

.sign-up-step-one__title-container {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
}

.sign-up-step-one__title {
    @include h3;
    margin-left: 52px;
    margin-top: 250px;
}

.sign-up-step-one__second-title {
    @include body-mono-reg;
    letter-spacing: 0px;
    margin: 20px 60px 0px 52px;
}

.sign-up-step-one__form-container {
    flex-basis: 450px;
    flex-shrink: 0;
    margin-top: 250px;
}

.sign-up-step-one__profession-label {
    margin-top: 10px;
    letter-spacing: -0.26px;
}

.sign-up-step-one__profession-select {
    margin-top: 10px;
}

.sign-up-step-one__team-label {
    margin-top: 32px;
    letter-spacing: -0.26px;
}

.sign-up-step-one__radio {
    margin-top: 20px;
}

.sign-up-step-one__confirm-button {
    margin-top: 32px;
    width: 100%;
    font-size: 13px;
    gap: 20px;
}

.sign-up-step-one__form-sign-in {
    @include body-mono-reg;
    letter-spacing: 0px;
    margin-top: 52px;
}

.sign-up-step-one__form-sign-in-create-link {
    text-decoration: underline;
    cursor: pointer;
}

.sign-up-step-one__exit-button-container {
    flex-basis: 50%;
    display: flex;
    position: relative;
}

.sign-up-step-one__exit-button {
    width: 16px;
    height: 16px;
    background-image: url('./icons/cross.svg');
    background-size: contain;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
}