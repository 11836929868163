@use 'shared' as *;

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    border: 1px solid $black-color;

    cursor: pointer;
    transition-duration: 200ms;

    &-secondary-with-icon {
        gap: 10px;
        padding: 0px 12px;
    }

    &_white {
        background-color: $white-color;
        color: $black-color;
    }

    &_large {
        @include button;
        height: 50px;
    }

    &_medium {
        @include tools;
        height: 40px;
    }

    &_small {
        @include tools;
        height: 32px;
    }

    &_dark {
        background-color: $black-color;
        color: $white-color;

        &>* {
            filter: brightness(0) saturate(100%) invert(100%) sepia(88%) saturate(21%) hue-rotate(176deg) brightness(105%) contrast(100%);
        }
    }
}

.button:hover:not(.button:disabled) {
    background-color: $gray-color;
    border: 1px solid $gray-color;
    color: $black-color;

    &>* {
        filter: none;
    }
}

.button:disabled {
    cursor: default;
    background-color: $light-gray-color;
    border-color: $light-gray-color;

    &>* {
        filter: brightness(0) saturate(100%) invert(71%) sepia(10%) saturate(80%) hue-rotate(357deg) brightness(96%) contrast(83%);
    }
}