@use "shared" as *;

.account__wrapper {
  position: absolute;
  inset: 0px;
  background: #00000080;
  z-index: 100;
}

.account {
  background-color: $white-color;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1056px;
}

.account__exit-button {
  width: 16px;
  height: 16px;
  background-image: url("./icons/close.svg");
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.account__title {
  @include h5;
  position: absolute;
  top: 52px;
  left: 52px;
}

.account__content {
  width: 450px;
  min-height: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.account__form {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.account__form-label {
  @include body-mono;
}

.account__form-email-container {
  display: flex;
  gap: 8px;
}

.account__email-note {
  @include table;
  opacity: 0.4;
  position: absolute;
  bottom: -20px;
}

.account__form-email {
  flex-grow: 1;
}

.account__newsletter-form {
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: flex-start;
}

.account__newsletter-input {
  display: flex;
  border: 1px solid $neutral-gray-color;
}

.account__newsletter-item {
  display: flex;
  align-items: center;
  padding: 14px;
  gap: 6px;
  cursor: pointer;

  &_active {
    background-color: $coconut-gray-color;
    cursor: default;
    pointer-events: none;
  }
}

.account__newsletter-checkmark-icon {
  width: 16px;
  height: 16px;
  background-image: url("./icons/checkmark.svg");
  background-size: contain;
  display: none;

  &_active {
    display: block;
  }
}

.account__newsletter-item-text {
  @include table;
}

.account__button {
  margin-top: 38px;
  gap: 2px;
  display: flex;
  align-self: flex-start;
  cursor: pointer;
}

.account-button-delete {
  height: 80px;
}

.account__logout-icon {
  width: 16px;
  height: 16px;
  background-image: url("./icons/logout.svg");
  background-size: contain;
}

.account__delete-icon {
  width: 16px;
  height: 16px;
  background-image: url("./icons/delete.svg");
  background-size: contain;
}

.account__button-text {
  @include body-mono;
  transform: translate(0px, 1px);
}

.account__deleting {
  height: 80px;
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: flex-start;
}

.account__deleting-title {
  @include body-mono;
}

.account__deleting-buttons {
  display: flex;
  gap: 10px;
}