@use "shared" as *;

.drawing {
  position: relative;
  background-color: $white-color;
  background-image: url("./icons/cross.svg");
  border-right: 1px solid $gray-color;
  border-left: 1px solid $gray-color;
  overflow: hidden;
  grid-area: drawing;

  @media (max-width: 1280px) {
    border-right: none;
    border-bottom: 1px solid $gray-color;
  }
}

.drawing__canvas {
  position: absolute;
  inset: 0px;
}

.drawing__title {
  position: absolute;
  top: 24px;
  left: 20px;
  color: #1a1c1f;
}

.drawing__prototype-loader {
  position: absolute;
  inset: 0px;
  bottom: 103px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawing__prototype-loader-spinner {
  background-image: url(./icons/loader.gif);
  width: 70px;
  height: 70px;
  background-size: contain;
}

.drawing__footer {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.drawing__footer-gap {
  flex-grow: 1;
}

.drawing__footer-bar {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 16px;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5.099999904632568px);

  &_no-filter {
    backdrop-filter: none;
  }
}

.drawing__footer-background-selector {
  height: 36px;
  display: flex;
  border: 1px solid $neutral-gray-color;

  &_disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: default;
  }
}

.drawing__footer-background-option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 0px 14px;
  cursor: pointer;

  &_selected {
    background-color: $coconut-gray-color;
    cursor: default;
  }

  &:hover:not(&_selected) {
    background-color: $gray-color;
  }
}

.drawing__footer-background-option-text {
  @include table;
  transform: translate(0px, 1px);
}

.drawing__redraw-buttons {
  position: relative;
  display: flex;
  gap: 2px;
  padding: 16px;
}

.drawing__inpaint-button {
  gap: 6px;
  height: 36px;
  padding: 0px 16px;
}

.drawing__inpaint-button-text {
  transform: translate(0px, 1px);
}

.drawing__inpaint-small-button {
  width: 36px;
  height: 36px;
  padding: 0;
}

.drawing__inpaint-resume-button {
  background-color: $blue-color;
  border: none;
}

.drawing__footer-replace-button-container {
  padding: 0px 16px;
  display: flex;
  align-items: center;
  width: 0px;
  flex-grow: 1;
}

.drawing__footer-replace-button-file-name {
  @include table;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drawing__footer-replace-button-file-ext {
  @include table;
}

.drawing__footer-replace-button-file-close-button {
  margin-left: 4px;
  cursor: pointer;
  flex-shrink: 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.drawing__zoom-undefined {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: top left;
  z-index: 7;
}

.drawing__undefined-cover {
  position: absolute;
  inset: 50% 0px 0px;
  transform: translate(0px, -50%);
  user-select: none;
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.5);
}

.drawing__canvas-undefined {
  position: absolute;
  inset: 0px;
  z-index: 10;
}

.upload__frame-container {
  position: absolute;
  inset: 0px;
  bottom: 36px;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_dragging {
    background-color: $light-blue-color;
  }
}

.upload__frame {
  background-color: $white-color;
  position: relative;
  width: calc(100% - 40px);
  height: 400px;
  z-index: 5;
}

.upload__browse {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 50%;
  background-color: $black-color;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    width: 100%;

    &~.upload__sample {
      width: 0%;
    }
  }
}

.upload__browse-title {
  @include body-mono;
  color: $white-color;
}

.upload__browse-button {
  @include body-mono;
  align-self: center;
  color: $white-color;
}

.upload__browse-note {
  @include table;
  color: $white-color;
  opacity: 0.4;
}

.upload__sample {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 50%;
  transition: width 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    width: 100%;

    &~.upload__browse {
      width: 0%;
    }
  }
}

.upload__sample-background {
  background: #FFFFFF url('./images/sample.png') no-repeat 50% 50%;
  position: absolute;
  height: 100%;
  right: 0px;
  width: 100%;
  min-width: calc((100vw / 2 - 122px - 40px) / 2);
}

.upload__sample-background-shadow {
  position: absolute;
  inset: 0px;
  background-color: #3030307D;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
}

.upload__sample-title {
  @include body-mono;
  color: $white-color;
}

.upload__sample-button {
  @include body-mono;
  width: 168px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black-color;
  color: $white-color;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.upload__frame-checked {
  @include table;
  color: $error-color;
  position: absolute;
  bottom: -25px;
}

.zoom-panel {
  margin-left: 10px;
  display: flex;
  position: relative;
}

.zoom-panel__button {
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    background-color: $gray-color;
  }

  &_plus {
    background-image: url(./icons/zoom_plus.svg);
  }

  &_minus {
    background-image: url(./icons/zoom_minus.svg);
  }

  &_fit {
    background-image: url(./icons/fit.svg);
  }
}

.zoom-panel__arrow {
  width: 14px;
  height: 36px;
  transform: rotate(180deg);
  background-image: url(./icons/zoom-arrow.svg);
  background-size: contain;
  cursor: pointer;

  &:hover {
    background-color: $gray-color;
  }

  &_opened {
    transform: rotate(0deg);
  }
}

.zoom-panel__tools {
  position: absolute;
  display: none;
  flex-direction: column;
  padding: 15px 0px;
  background-color: $white-color;
  border: 1px solid $neutral-gray-color;
  transform: translate(-5px, calc(-100% - 2px));

  &_opened {
    display: flex;
  }
}

.zoom-panel__tool {
  width: 116px;
  height: 26px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  gap: 5px;

  &:hover {
    background-color: $light-gray-color;
  }
}

.zoom-panel__tool_plus-icon {
  width: 22px;
  height: 22px;
  background-image: url(./icons/zoom_plus.svg);
  background-size: contain;
}

.zoom-panel__tool_minus-icon {
  width: 22px;
  height: 22px;
  background-image: url(./icons/zoom_minus.svg);
  background-size: contain;
}

.zoom-panel__tool_fit-icon {
  width: 22px;
  height: 22px;
  background-image: url(./icons/fit.svg);
  background-size: contain;
}

.zoom-panel__tool-text {
  @include table;
  transform: translate(0px, 1px);
}