@use 'shared' as *;

.input {    
    @include table;
    outline: none;
    border: 1px solid $neutral-gray-color;
    padding: 0px 10px;
    min-width: 30px;
}

.input-error{
    position: relative;
    border: 1px solid $error-color;
}

.input-regular {
    height: 40px;
}

.input-table {
    height: 33px;
}

.input:focus {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    // border: 1px solid $black-color;
}

.input::placeholder {
    @include table;
    color: $gray-color;
}

.segment__desc>textarea {
    overflow: auto;
    resize: none;
    max-height: 30px;
    height: 27px;
}


// .upload-attach__note>textarea{
//     overflow: scroll;
//     resize: none;
//     max-height: 65px;
//     height: 65px;
// }

.segment__cell4>textarea:focus {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.segment__desc>textarea::-webkit-scrollbar {
    display: none;
}


// .upload-attach__note>textarea:focus {
//     box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
// }

.textarea_wrapper {
    border: none;
    width: 100%;
    padding: 0;
}