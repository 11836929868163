@use 'shared' as *;

.switcher {
    width: 28px;
    height: 14px;
    border: 1px solid $black-color;
    border-radius: 7px;
    display: flex;
    padding: 0px 3px;
    align-items: center;
    cursor: pointer;
    transition-duration: 500ms;
    transition-property: padding;

    &:not(&_disabled):hover {
        opacity: 0.6;
    }

    &_active {
        padding: 0px 3px 0px 16.7px;
        border-color: $blue-color;
    }

    &_disabled {
        opacity: 0.2;
        cursor: default;
    }
}

.switcher__stick {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $black-color;
}

.switcher__stick_active {
    background-color: $blue-color;
    transition-duration: 500ms;
}