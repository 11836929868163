@use 'shared' as *;

.qualities {
    padding-top: 44px;
    display: grid;
    grid-template-columns: minmax(0, 400fr) minmax(0, 70fr) minmax(0, 400fr);
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 56px);
    padding-right: 50px;
}

.qualities__col {
    display: flex;
    flex-direction: column;
}

.qualities__forms {
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.qualities__form {
    @include body-mono;
    display: flex;
    flex-direction: column;
    gap: 10px;
}