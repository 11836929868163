@use "shared" as *;

.upload__header {
  height: 41px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray-color;
}

.header__menu {
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: 122px 44px;
  grid-template-rows: 41px auto;
  height: fit-content;
  border-right: 1px solid $gray-color;

  &:focus .header__menu-select-items {
    display: block;
  }

  &:focus .header__menu-select {
    background-color: $light-gray-color;
  }
}

.header__menu-select {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  margin-bottom: 1px;

  
  &:hover {
    background-color: $light-gray-color;
  }
}

.header__menu-select-icon {
  width: 63px;
  height: 11px;
  background-image: url("./icons/newLogo.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.header__menu-select-arrow {
  width: 9px;
  height: 6px;
  background-image: url("./icons/button.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.header__menu-home {
  border-left: 1px solid $gray-color;
  background-image: url("./icons/home.svg");
  background-size: 21px 21px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  &_disabled {
    background-image: url("./icons/home_disabled.svg");
    pointer-events: none;
    cursor: default;
  }
}

.header__menu-select-items {
  display: none;
  grid-column: 1 / 3;
  background-color: $white-color;
  border-bottom: 1px solid $gray-color;
  padding: 20px 0px;
}

.header__menu-select-item {
  height: 26px;
  display: flex;
  align-items: center;
  padding: 0px 26px;
  cursor: pointer;

  &:hover {
    background-color: $light-gray-color;
  }

  &_disabled {
    pointer-events: none;
    opacity: 0.2;
  }
}

.upload__header-state {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.size-span {
  white-space: pre;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  opacity: 0;
  min-width: 5px;
  user-select: none;
  vertical-align: top;
  @include body-mono;
  text-transform: uppercase;
}

.upload__register {
  @include body-mono;
  cursor: pointer;
  padding: 0 28px;
  display: flex;
  align-items: center;
  text-decoration: underline;

  &:hover {
    background-color: $light-gray-color;
    text-decoration: none;
  }
}

.sketch__header-register {
  cursor: pointer;
  @include body-mono;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 25px;
  text-decoration: underline;
  transition: all 0.3s;

  &:hover {
    background-color: $light-gray-color;
    text-decoration: none;
  }
}

.settings_title-input {
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0px 4px;
  display: inline-block;
}

.settings_title-input>input {
  height: 100%;
  background-color: $light-gray-color;
  color: inherit;
  top: 0;
  left: 0;
  font-size: inherit;
  line-height: inherit;
  padding: inherit;
  position: absolute;
  box-sizing: border-box;
  @include body-mono;
  border: none;
}

.settings_title-input>input:focus {
  outline: none;
}