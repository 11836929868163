@use "shared" as *;

.extended_help_home {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > p {
    letter-spacing: 0;
    @include body-mono-reg;
    text-transform: none;
    margin: 0;
  }
}

.extended_help_home-image {
  margin-top: 8px;
  width: 441px;
  height: 244px;
  background-image: url("../icons/home_image.png");
  background-size: 441px 244px;
}

.beta {
  padding: 2px 4px 3px 4px;
  background-color: $gray-color;
  color: $black-color;
  font-family: "Moderat";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 108%;
  letter-spacing: -0.22px;
  text-transform: uppercase;
}

.extended_help_home_link-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;

  & > p {
    @include body-mono;
    margin: 0;
    transition: 0.3s;
    text-decoration: underline;
  }
}

.home_link-wrapper-icon {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  margin-bottom: 1px;
}

.note_icon {
  background-image: url("../icons/note.svg");
}
.tutorial_icon {
  background-image: url("../icons/tutorial.svg");
}

.extended_help_home_quick-start {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include body-mono-reg;
  letter-spacing: 0;
  margin: 0;

  & > p {
    margin: 0;
  }
}

.section_title {
  @include body-m;
  margin: 0;
  margin-bottom: 13px;
}

.section_title-desrc_obj {
  @include body-m;
  margin: 0;
  margin-bottom: 8px;
}

.quick-start_item {
  display: flex;
  gap: 10px;

  & > p {
    @include body-mono-reg;
    letter-spacing: 0;
    margin: 0;
  }
}

.quick-start_item-column {
  display: flex;
  flex-direction: column;

  & > p {
    @include body-mono-reg;
    letter-spacing: 0;
    margin: 0;
  }
}

.highlight-span {
  @include body-mono-reg;
  letter-spacing: 0;
  font-weight: 500;
}

.underline_span {
  cursor: pointer;
  @include body-mono-reg;
  letter-spacing: 0;
  text-decoration: underline;
}

.dont_show {
  display: flex;
  align-items: center;
  gap: 8px;

  & > p {
    margin: 0;
    @include body-mono-reg;
    letter-spacing: 0;
  }
}

.dont_show_checkbox {
  cursor: pointer;
  height: 17px;
  width: 17px;
  background-color: $white-color;
  border: 1px solid $neutral-gray-color;
  margin-bottom: 6px;
}

.dont_show_checkbox-arrow {
  width: 15px;
  height: 15px;
  background-image: url("../icons/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px 11px;
}

.extended_help_upload {
  padding: 50px 20px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > p {
    @include body-mono-reg;
    text-transform: none;
    margin: 0;
    letter-spacing: 0;
  }
}

.extended_help_upload-example {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > p {
    margin: 0;
    @include hint;
    font-size: 13px;
    opacity: 0.6;
    letter-spacing: 0;
  }
}

.extended_help_upload-example_image {
  width: 100%;
  height: 244px;
  background-color: $white-color;
}

.extended_help-header_section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  & > p {
    margin: 0;
    letter-spacing: 0;
  }
}

.extended_help-header_section-help {
  cursor: pointer;
  @include body-mono;
  margin: 0;
  transition: 0.3s;

  &:hover {
    text-decoration: underline;
  }
}

.extended_help-header_section_title {
  @include body-mono;
  margin: 0;
  opacity: 0.6;
}

.extended_help_mask_selection {
  padding: 24px 20px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > p {
    @include body-mono-reg;
    text-transform: none;
    margin: 0;
    letter-spacing: 0;
  }
}

.section-descr {
  padding-bottom: 24px;
}

.extended_help_rendering {
  padding: 24px 20px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;

  & > p {
    @include body-mono-reg;
    text-transform: none;
    margin: 0;
    letter-spacing: 0;
  }
}

.help_rendering-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > p {
    @include body-mono-reg;
    text-transform: none;
    margin: 0;
    letter-spacing: 0;
  }
}

.item-list {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > p {
    @include body-mono-reg;
    text-transform: none;
    margin: 0;
    letter-spacing: 0;
  }
}

.help_rendering-item-link {
  display: flex;
  flex-direction: row;
  gap: 10px;

  & > p {
    @include body-mono-reg;
    text-transform: none;
    margin: 0;
    letter-spacing: 0;
  }
}

.read_more-link {
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
}

.go_arrow {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-image: url("../icons/go.svg");
  margin-bottom: 3px;
}

.extended_help_iterface {
  padding: 24px 20px 12px;
  display: flex;
  flex-direction: column;
}

.interface_dropdown_wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $neutral-gray-color;
  transition: 0.3s;
}

.interface_dropdown {
  cursor: pointer;
  width: 100%;
  padding: 24px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-title_wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.dropdown-title {
  @include body-m;
  margin: 0;
}

.dropdown-title-beta_marker {
  // position: absolute;
  padding: 3px 4px 2px 4px;
  background-color: $gray-color;
  color: $black-color;
  font-family: "Moderat";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 108%;
  letter-spacing: -0.22px;
  text-transform: uppercase;
  user-select: none;
}

.dropdown_icon-plus {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-image: url("../icons/plus.svg");
}

.dropdown_icon-minus {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-image: url("../icons/minus.svg");
}

.dropdown_item {
  //   display: flex;
  //   max-height: 0;
  transition: max-height 350ms ease-out;
  overflow: hidden;
  //   max-height: 100%;

  //   &_open {
  //     // max-height: 400px;
  //     max-height: 100%;
  //   }
}

.dropdown_item-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 48px;
}

.dropdown_item-info-image {
  background-color: $white-color;
  height: 244px;
  width: 100%;
}

.extended_help_create-masks {
  padding: 48px 20px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  transition: all 300ms ease-out;

  & > p {
    @include body-mono-reg;
    text-transform: none;
    margin: 0;
    letter-spacing: 0;
  }
}

.dropdown_items-wrapper {
  display: flex;
  flex-direction: column;

  & > p {
    @include body-m;
    margin: 0;
  }
}

.launch_tutorial-box {
  margin-bottom: 28px;
}
