@use "shared" as *;

.products__attach-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.products__norma {
  margin-top: 23px;
  display: flex;
  gap: 12px;
  padding: 16px 10px;
  background-color: $coconut-gray-color;
}

.products__norma-icon {
  width: 22px;
  height: 22px;
  background-image: url("../icons/norma.svg");
  background-size: contain;
}

.products__norma-text {
  @include body-mono-reg;
  flex-grow: 1;
}

.products__norma-read_more {
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.products__norma-read_more-text {
  @include body-mono;
  text-decoration: underline;
}

.products__norma-read_more-icon {
  width: 20px;
  height: 20px;
  background-image: url("../icons/readMore.svg");
  background-size: contain;
  transform: translate(0, -1px);
}

.products__rounds {
  margin-top: 22px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.products__round {
  display: flex;
  align-items: center;
  gap: 10px;
}

.products__round-icon {
  width: 16px;
  height: 16px;
  background-image: url("./icons/round.svg");
  background-size: contain;
  cursor: pointer;

  &_active {
    background-image: url("./icons/round_active.svg");
    pointer-events: none;
  }
}

.products__round-text {
  @include body-mono-reg;
  transform: translate(0, 1px);
}

.products__applied-item-container {
  margin-top: 26px;
  display: flex;
  align-items: center;
  height: 22px;
  flex-shrink: 0;
  gap: 25px;
}

.products__applied-item-title {
  @include body-mono;
  color: rgba(26, 28, 31, 0.50);
  transform: translate(0, 1px);
}

.products__applied-item {
  display: flex;
  align-items: center;
  gap: 9px;
}

.products__applied-item-name {
  @include table;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.products__applied-item-remove-icon {
  width: 22px;
  height: 22px;
  background-image: url("./icons/remove.svg");
  background-size: contain;
  cursor: pointer;

  &:hover {
    background-color: $gray-color;
  }
}

.products__applied-item-empty {
  @include table;
}

.products__grid {
  margin-top: 22px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 20px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.products__model {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 295px;
  border: 1px solid;
  border-color: $neutral-gray-color;
  transition: 700ms border-color;
}

.products-attach__model-image-wrapper {
  height: 180px;
  width: 100%;
}

.products-attach__model-image {
  width: 100%;
  height: 180px;
  object-fit: contain;
}

.products-attach__model-description {
  @include table;
  line-height: normal;
}

.products-attach__model-info-wrapper {
  padding: 0 16px 20px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.products-attach__model-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 5px;
}

.products-attach__model-designed {
  width: 0;
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &>p {
    margin: 0;
  }
}

.model-designed_by {
  @include body-mono;
  letter-spacing: -0.26px;
  opacity: 0.4;
}