@use "shared" as *;

.sketch-list {
  @include h3;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.4;
  height: 42px;

  &:hover {
    opacity: 1;
  }

  &__selected {
    opacity: 1;
    padding-left: 10px;
    background-color: $light-gray-color;

    .sketch-list__icon {
      display: flex;
    }
  }
}

.sketch-list__name {
  @include h3;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 0px;
  flex-grow: 1;
}

.sketch-list__icons_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  flex-shrink: 0;
}

.sketch-list__icon {
  position: relative;
  width: 34px;
  height: 34px;
  display: none;
  transition: 0.3s;

  &:hover {
    background-color: $neutral-gray-color;
  }
}

.rename_icon {
  background-image: url("../icons/rename.svg");
}

.copy_icon {
  background-image: url("../icons/copy.svg");
}

.delete_icon {
  background-image: url("../icons/delete.svg");
}

.sketch-list__name-input {
  @include h3;
  border: 1px solid $black-color;
  outline: none;
  padding: 0px 9.3px;
  padding-bottom: 1px;
  width: 100%;
  height: 42px;
}