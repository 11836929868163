.shadow {
    position: absolute;
    inset: 0px;
    z-index: 100;
    background-color: #303030;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease;

    &_showed {
        opacity: 0.49;
        pointer-events: auto;
    }
}