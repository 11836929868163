@use "shared" as *;

.select-form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    outline: none;

    &:focus .select-form__options {
        display: block;
    }
}

.select-form__input {
    position: relative;
    width: 100%;
    outline: 1px solid $neutral-gray-color;
    outline-offset: -1px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &_errored {
        outline-color: $error-color;
    }
}

.select-form__lookup {
    @include table;
    margin: 12px 10px;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.select-form__placeholder {
    @include table;
    margin: 12px 10px;
    flex-grow: 1;
    opacity: 0.4;
}

.select-form__value {
    @include table;
    margin: 12px 10px;
    flex-grow: 1;
}

.select-form__arrow-container {
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.select-form__arrow {
    &_rotated {
        transform: rotate(180deg);
    }
}

.select-form__error {
    @include table;
    margin-top: 8px;
    color: transparent;
    pointer-events: none;
    user-select: none;

    &_visible {
        color: $error-color;
    }
}

.select-form__options {
    position: absolute;
    display: none;
    left: 0px;
    right: 0px;
    z-index: 1;
    bottom: 0px;
    transform: translate(0px, 100%);
    padding: 15px 0px;
    border-bottom: 1px solid $neutral-gray-color;
    border-left: 1px solid $neutral-gray-color;
    border-right: 1px solid $neutral-gray-color;
    background-color: $white-color;
    max-height: 300px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.select-form__option {
    @include table;
    height: 27px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
        background-color: $light-gray-color;
    }
}

.select-form__option-selected-icon {
    width: 18px;
    height: 18px;
    background-image: url('./icons/option_selected.svg');
    background-size: contain;
}