@use "shared" as *;

.saving {
    @include body-m;
    position: absolute;
    width: 157px;
    height: 52px;
    background-color: $white-color;
    color: #5A595999;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    right: 6px;
    bottom: 6px;
    z-index: 9;
    opacity: 1;
    transition: opacity 1.5s ease-out;
}

.saving__saving-svg {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.saving__saved {
    opacity: 0;
    transition: opacity 1s ease-out;
}