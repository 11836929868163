@use 'shared' as *;

.irreversible-step {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 52px;
    gap: 32px;
    max-width: 430px;
    justify-content: center;
}

.irreversible-step__title-container {
    display: flex;
    gap: 8px;
    align-items: flex-end;
}

.irreversible-step__title {
    @include h5;
    text-transform: none;
}

.irreversible-step__text {
    font-family: "Simplon Mono";
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $black-color;
}

.irreversible-step__buttons {
    display: flex;
    gap: 10px;
}