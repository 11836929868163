@use "shared" as *;

.segment {
    display: grid;
    grid-template-columns: 27px 31px 47px 38px minmax(0, 1fr) 63px 39px;
    min-height: 57px;

    &_hovered {
        background-color: $light-blue-color;
    }
}

.segment__dnd-cell {
    display: flex;
    padding-top: 17px;
    justify-content: flex-end;
    border-bottom: 1px solid $medium-gray-color;
}

.segment__dnd-handle-container {
    width: 22px;
    height: 22px;
}

.segment__check-box-cell {
    padding: 20px 0px 0px 5px;
    border-bottom: 1px solid $medium-gray-color;
}

.segment__color-cell {
    display: flex;
    gap: 4px;
    padding: 23px 3px 0px 8px;
    border-bottom: 1px solid $medium-gray-color;
}

.segment__color {
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    flex-shrink: 0;
}

.segment__name {
    @include table;
}

.segment__inpaint-cell {
    padding: 12px 0px 0px 6px;
    border-bottom: 1px solid $medium-gray-color;
}

.segment__icon {
    cursor: pointer;
    width: 32px;
    height: 32px;

    &:hover {
        background-color: $gray-color;
    }
}

.segment__inpaint-icon {
    background-image: url("./icons/inpaint.svg");
}

.segment__inpaint-changed-icon {
    background-image: url("./icons/inpaint_changed.svg");
}

.segment__inpaint-empty-icon {
    background-image: url("./icons/inpaint.svg");
    opacity: 0.2;
    cursor: default;
}

.segment__inpaint-completed-icon {
    background-image: url("./icons/inpaint_completed.svg");
}

.segment__inpaint-pending-icon {
    background-image: url("./icons/inpaint_pending.svg");

    &:hover {
        background-color: transparent !important;
        background-image: url("./icons/inpaint_stop.svg");
    }
}

.segment__inpaint-in-progress-icon {
    background-image: url("./icons/inpaint_in-progress.svg");
    animation: spin 2500ms infinite linear;

    &:hover {
        background-color: transparent !important;
        background-image: url("./icons/inpaint_stop.svg");
        animation: none;
    }
}

.segment__inpaint-pause-icon {
    background-image: url("./icons/inpaint_pause.svg");

    &:hover {
        background-color: transparent !important;
        background-image: url("./icons/inpaint_stop.svg");
    }
}

.segment__input-cell {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $medium-gray-color;

    &:focus-within {
        border-bottom: 1px solid $black-color;
    }
}

.segment__text-area {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: text;
}

.segment__description {
    @include table;
    margin: 12px 10px;
    flex-grow: 1;
    resize: none;
    outline: none;
    border: none;
    background: transparent;

    &::placeholder {
        @include table;
        color: $gray-color;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: $medium-gray-color;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
}

.segment__attachment {
    margin: 3px 12px;
    display: flex;
    align-items: center;
    width: calc(100% - 24px);
}

.segment__attachment-image {
    width: 50px;
    height: 50px;
    object-fit: contain;
    flex-shrink: 0;
}

.segment__attachment-filename {
    font-family: "Simplon Mono";
    text-transform: none;
    font-weight: 400;
    letter-spacing: -0.7px;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 10px;
}

.segment__attachment-remove {
    margin-left: 4px;
    flex-shrink: 0;
    cursor: pointer;
}

.segment__attachment-select-file {
    margin: 12px 10px;
    width: 100%;
    height: 100%;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px 8px;
}

.segment__inpaint-type-cell {
    position: relative;
    border-bottom: 1px solid $medium-gray-color;
}

.segment__text-inpaint-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 4px;
    top: 12px;
}

.segment__attachment-inpaint-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 32px;
    top: 12px;
}

.segment__remove-cell {
    padding-top: 12px;
    border-bottom: 1px solid $medium-gray-color;
}

.segment__icon {
    cursor: pointer;
    width: 32px;
    height: 32px;

    &:hover {
        background-color: $gray-color;
    }
}
