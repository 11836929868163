@use "shared" as *;

.group {
    display: grid;
    grid-template-columns: 27px 31px 47px 38px minmax(0, 1fr) 63px 39px;
    min-height: 57px;

    &_hovered {
        background-color: $light-blue-color;
    }
}

.group__dnd-cell {
    display: flex;
    padding-top: 17px;
    justify-content: flex-end;
    border-bottom: 1px solid $medium-gray-color;
}

.group__dnd-handle-container {
    width: 22px;
    height: 22px;
}

.group__check-box-cell {
    padding: 20px 0px 0px 5px;
    border-bottom: 1px solid $medium-gray-color;
}

.group__open-cell {
    padding: 13px 0px 0px 0px;
    border-bottom: 1px solid $medium-gray-color;
}

.group__open-button {
    width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
    transform: translate(0px, -1px);

    &:hover {
        background-color: $gray-color;
    }
}

.group__inpaint-cell {
    padding: 12px 0px 0px 6px;
    border-bottom: 1px solid $medium-gray-color;
}

.group__icon {
    cursor: pointer;
    width: 32px;
    height: 32px;

    &:hover {
        background-color: $gray-color;
    }
}

.group__inpaint-icon {
    background-image: url("./icons/inpaint.svg");
}

.group__inpaint-changed-icon {
    background-image: url("./icons/inpaint_changed.svg");
}

.group__inpaint-empty-icon {
    background-image: url("./icons/inpaint.svg");
    opacity: 0.2;
    cursor: default;
}

.group__inpaint-completed-icon {
    background-image: url("./icons/inpaint_completed.svg");
}

.group__inpaint-pending-icon {
    background-image: url("./icons/inpaint_pending.svg");

    &:hover {
        background-image: url("./icons/inpaint_stop.svg");
    }
}

.group__inpaint-in-progress-icon {
    background-image: url("./icons/inpaint_in-progress.svg");
    animation: spin 1s infinite linear;

    &:hover {
        background-image: url("./icons/inpaint_stop.svg");
        animation: none;
    }
}

.group__inpaint-pause-icon {
    background-image: url("./icons/inpaint_pause.svg");
    animation: spin 1s infinite linear;

    &:hover {
        background-image: url("./icons/inpaint_stop.svg");
        animation: none;
    }
}

.group__input-cell {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid $medium-gray-color;

    &:focus-within {
        border-bottom: 1px solid $black-color;
    }
}

.group__input-container {
    min-height: 57px;
    display: flex;
    align-items: center;
}

.group__attachment {
    margin: 3px 12px;
    display: flex;
    align-items: center;
    width: calc(100% - 24px);
}

.group__attachment-image {
    width: 50px;
    height: 50px;
    object-fit: contain;
    flex-shrink: 0;
}

.group__attachment-filename {
    font-family: "Simplon Mono";
    text-transform: none;
    font-weight: 400;
    letter-spacing: -0.7px;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 10px;
}

.group__attachment-remove {
    margin-left: 4px;
    flex-shrink: 0;
    cursor: pointer;
}

.group__attachment-select-file {
    margin: 12px 10px;
    width: 100%;
    height: 100%;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px 8px;
}

.group__text-area {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: text;
}

.group__description {
    @include table;
    margin: 12px 10px;
    flex-grow: 1;
    resize: none;
    outline: none;
    border: none;
    background: transparent;

    &::placeholder {
        @include table;
        color: $gray-color;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: $medium-gray-color;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
}

.group__editing-buttons {
    height: 41px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0px 10px;
    transform: translate(0px, -11px);

    &_attachment {
        margin-top: 10px;
    }
}

.group__editing-cancel-button {
    background-color: transparent;
}

.group__inpaint-type-cell {
    position: relative;
    border-bottom: 1px solid $medium-gray-color;
}

.group__text-inpaint-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 4px;
    top: 12px;
}

.group__attachment-inpaint-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 32px;
    top: 12px;
}

.group__ungroup-cell {
    padding: 12px 0px 0px 0px;
    border-bottom: 1px solid $medium-gray-color;
}

.group__ungroup-cell-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.group__ungroup-icon {
    overflow: visible;
}

.group__segment {
    display: grid;
    grid-template-columns: 27px 31px 95px minmax(0, 1fr) 63px 39px;
    min-height: 57px;

    &:hover {
        background-color: $light-blue-color;
    }
}

.group__segment-dnd-cell {
    display: flex;
    padding-top: 17px;
    justify-content: flex-end;
    border-bottom: 1px solid $medium-gray-color;
}

.group__segment-dnd-handle-container {
    width: 22px;
    height: 22px;
}

.group__segment-check-box-cell {
    padding: 20px 0px 0px 5px;
    border-bottom: 1px solid $medium-gray-color;
}

.group__segment-color-cell {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
    padding: 23px 10px 0px 0px;
    border-bottom: 1px solid $medium-gray-color;
}

.group__segment-color {
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    flex-shrink: 0;
}

.group__segment-name {
    @include table;
}

.group__segment-input-cell {
    position: relative;
    display: flex;
    align-items: center;
    background-color: $light-gray-color;
    border-bottom: 1px solid $medium-gray-color;
}

.group__segment:hover .group__segment-input-cell {
    background: linear-gradient(0deg, rgba(4, 99, 225, 0.10) 0%, rgba(4, 99, 225, 0.10) 100%), #F3FCFF;
}

.group__segment-disabled {
    display: none;
    position: absolute;
    inset: 0px;
    padding: 10px;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}

.group__segment:hover .group__segment-disabled {
    display: flex;
}

.group__segment-disabled-item {
    display: flex;
    align-items: center;
    gap: 6px;
}

.group__segment-disabled-item-text {
    @include table;
    transform: translate(0px, 1px);
    color: #616566;
}

.group__segment-attachment {
    margin: 3px 12px;
    display: flex;
    align-items: center;
    width: calc(100% - 24px);
}

.group__segment-attachment-image {
    width: 50px;
    height: 50px;
    object-fit: contain;
    flex-shrink: 0;
}

.group__segment-attachment-filename {
    font-family: "Simplon Mono";
    text-transform: none;
    font-weight: 400;
    letter-spacing: -0.7px;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 10px;
}

.group__segment-text {
    @include table;
    color: $gray-color;
    margin: 12px 10px;
    max-height: 50px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::placeholder {
        @include table;
        color: $gray-color;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: $medium-gray-color;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
}

.group__segment-empty-cell {
    background-color: $light-gray-color;
    border-bottom: 1px solid $medium-gray-color;
}

.group__segment:hover .group__segment-empty-cell {
    background: linear-gradient(0deg, rgba(4, 99, 225, 0.10) 0%, rgba(4, 99, 225, 0.10) 100%), #F3FCFF;
}

.group__segment-remove-cell {
    display: flex;
    align-items: center;
    background-color: $light-gray-color;
    border-bottom: 1px solid $medium-gray-color;
}

.group__segment-remove-icon {
    width: 32px;
    height: 32px;
    background-image: url('./icons/remove.svg');
    background-size: contain;

    &:hover {
        background-color: $gray-color;
    }
}