@use 'shared' as *;

.sign-up-step-one-radio {
    display: flex;
    width: 310px;
    justify-content: space-between;
    gap: 12px;
}

.sign-up-step-one-radio-button {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.sign-up-step-one-radio-button__label {
    margin-left: 10px;
    color: $black-color;
}