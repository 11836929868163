
@use 'shared' as *;

.radio {
    width: 16px;
    height: 16px;
    cursor: pointer;

    &_selected .black-fill {
        fill: $black-color;
    }
}