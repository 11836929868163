@use 'shared' as *;

.checkbox {
    width: 16px;
    height: 16px;
    cursor: pointer;

    &_active .black-fill {
        fill: $black-color;
    }

    &_black_active .black-fill{
        fill: $white-color;
    }
}