@use "shared" as *;

.list_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-area: list;

  @media (max-width: 1280px) {
    border-left: 1px solid $gray-color;
  }
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
}

.list_scrolling {
  &::-webkit-scrollbar-thumb {
    background-color: #c2c1c166;
    opacity: 1;
    transition: opacity 0.5s ease;
  }
}

.list__toolbar {
  display: flex;
  align-items: center;
  margin: 20px 20px 0px;
  background-color: $white-color;
}

.list__toolbar-button {
  height: 42px;
  min-width: 42px;
  @include tools;
  position: relative;
  display: flex;
  gap: 8px;
  padding: 0px 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  background-color: $white-color;

  &:not(&_disabled):not(&_selected):hover {
    background-color: $coconut-gray-color;
  }

  &_disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &_selected * {
    filter: brightness(0) saturate(100%) invert(20%) sepia(69%) saturate(3670%) hue-rotate(207deg) brightness(102%) contrast(97%);
  }
}

.list__toolbar-gap {
  max-width: 36px;
  flex-grow: 1;

  @media (max-width: 1650px) {
    max-width: 16px;
  }
}

.list__toolbar-big-gap {
  flex-grow: 1;
}

.list__export-button {
  justify-content: space-between;
  width: 104px;
  padding: 0px 16px 0px 12px;
}

.list__export-button-icon {
  width: 20px;
  height: 20px;
  background-image: url("./icons/export.svg");
  background-size: contain;
}

.list__table {
  background-color: $white-color;
  position: relative;
  margin: 0px 20px 20px;
}

.list__table-elements-wrapper {
  height: 100%;
  position: relative;
}

.list__head {
  display: grid;
  grid-template-columns: 58px 47px 38px 1fr;
  height: 28px;
  margin: 20px 20px 0px;
  background-color: $white-color;
}

.list__head-checkbox-cell {
  display: flex;
  align-items: center;
  padding: 0px 8px;
  justify-content: flex-end;
  background-color: #000;
  border-right: 1px solid rgba(144, 138, 130, 0.5);
}

.list__head-hash-cell {
  @include caption;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  background-color: #000;
  border-right: 1px solid rgba(144, 138, 130, 0.5);
  color: $gray-color;
}

.list__head-inpaint {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-right: 1px solid rgba(144, 138, 130, 0.5);
  cursor: pointer;

  &:hover {
    background-color: #484541;
  }
}

.list__head-definition-cell {
  @include caption;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 10px;
  background-color: #000;
  color: $gray-color;
}

.list__toolbar-button-text {
  @media (max-width: 1650px) {
    display: none;
  }
}

.recognizing__background {
  position: absolute;
  inset: 0px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%);
}

.recognizing__loader {
  background-image: url(./icons/loader.gif);
  width: 70px;
  height: 70px;
  background-size: contain;
}

.recognizing__number {
  @include table;
  position: absolute;
  top: 0;
  width: 32px;
  height: 32px;
  background-color: $gray-color;
  display: flex;
  align-items: center;
  justify-content: center;

  &_disabled {
    background-color: $light-gray-color !important;
  }
}