@use 'shared' as *;

.join-mask-notification__wrapper {
    position: absolute;
    inset: 0px;
    z-index: 90;
}

.join-mask-notification {
    background-color: $black-color;
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 453px;
    padding: 16px 16px 20px 20px;
    display: flex;
    flex-direction: column;
}

.join-mask-notification__exit {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    background-image: url(./icons/exit.svg);
    background-size: contain;
    cursor: pointer;
}

.join-mask-notification__title-line {
    display: flex;
    align-items: flex-end;
    gap: 4px;
}

.join-mask-notification__title-icon {
    width: 20px;
    height: 20px;
    background-image: url(./icons/warning.svg);
    background-size: contain;
}

.join-mask-notification__title {
    @include body-m;
    color: $white-color;
    opacity: 0.6;
}

.join-mask-notification__text {
    @include body-mono-reg;
    color: $white-color;
    margin-top: 32px;
}

.join-mask-notification__button-line {
    margin-top: 21px;
    display: flex;
    align-items: center;
    gap: 24px;
}

.join-mask-notification__buttons {
    display: flex;
    gap: 10px;
}

.join-mask-notification__checkbox-form {
    display: flex;
    align-items: center;
    gap: 8px;
}

.join-mask-notification__checkbox {
    width: 16px;
    height: 16px;
    background-image: url(./icons/checkbox.svg);
    background-size: contain;
    cursor: pointer;

    &_checked {
        background-image: url(./icons/checkbox_checked.svg);
    }
}

.join-mask-notification__checkbox-label {
    @include body-mono-reg;
    color: $white-color;
}