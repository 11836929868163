@use "shared" as *;

.skeleton {
    display: flex;
    height: 57px;
    border-bottom: 1px solid $medium-gray-color;
}

.skeleton__dnd-cell {
    display: flex;
    align-items: center;
}

.skeleton__dnd-icon {
    width: 22px;
    height: 22px;
    background-image: url('./icons/dnd.svg');
    background-size: contain;
}

.skeleton__check-box-cell {
    width: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skeleton__check-box-icon {
    width: 16px;
    height: 16px;
    background-image: url('./icons/checkbox.svg');
    background-size: contain;
}
